:root{
    --common-box-shadow: 0px 0px 5px 0px rgb(196 195 195 / 40%) !important;
}

@font-face {
	font-family: 'proxima_nova_rgregular';
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-regular-webfont.svg#proxima_nova_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}
@font-face {
	font-family: 'proxima_nova_rgbold';
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/proximanova-bold-webfont.svg#proxima_nova_rgbold') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'adelle_rgregular';
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot');
	src: url('https://litmus.com/fonts/Emails/adelle_reg-webfont.eot?#iefix') format('embedded-opentype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.woff') format('woff'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.ttf') format('truetype'),
		 url('https://litmus.com/fonts/Emails/adelle_reg-webfont.svg#adelle_rgregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

body{
	font-family: 'proxima_nova_rgregular', Helvetica, Arial, sans-serif;
}
h1{
   font-family: 'proxima_nova_rgbold', Helvetica, Arial, sans-serif;
}

/* input:focus { */
    /* border: 1px solid rgba(196, 195, 195, 0.753) !important; */
  /* } */

.page-wraper {
    background: #fff;
}

.atb-card {
    border-radius: 20px !important;
    padding:30px;
    text-align: center;
    margin: 10px;
    box-shadow: var(--common-box-shadow);
    margin-bottom: 30px;
}

.no-shadow-card {
    border-radius: 20px !important;
    padding:30px;
    text-align: center;
    margin: 10px;
    /* box-shadow: var(--common-box-shadow); */
    margin-bottom: 30px;
}

.atb-border {
    border-radius: 20px !important;
    padding:30px;
    text-align: center;
    margin: 10px;
    /* box-shadow: var(--common-box-shadow); */
    margin-bottom: 30px;
}

.atb-input-box{
    padding: 15px;
    border-radius: 5px;
    box-shadow: var(--common-box-shadow);
    background-color: #fff !important;
    border: none !important; 
}

.atb-input-box__control{
    padding: 8px !important;
    border-radius: 5px !important;
    box-shadow: var(--common-box-shadow) !important;
}

.atb-input-box__indicator-separator{
    display: none;
}

.atb-input-box__indicator{
    color: #3571f5 !important;
}

.atb-input-box :disabled{
    background-color: #e0e0e0 !important;
}

.atb-input-box :read-only{
    background-color: #e0e0e0 !important;
}

.dashboard-sidebar-avatar{
    height: 300px;
    width: 300px;
    border-radius: 50%;
}

.dashboard-section-heading{
    color: #1167B1;
    text-align: left !important;
    margin-bottom: 30px;
}

.upload-link{
    position: absolute;
	width: 50px;
	height: 50px;
	line-height: 45px;
	background: #fff;
	top: 60px;
	right: 100px;
	box-shadow: 0 0 10px 0 rgba(0,24,128,0.1);
	border-radius: 50%;
	color: #2E55FA;
	/* overflow: hidden; */
    padding: 0px 10px;
    display: flex;
    vertical-align: bottom;
    cursor: pointer !important;
}

.intro-text-container{
    height: 130px !important;
}


.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #0087ca;
  }
  
  .hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #0087ca;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  
  .hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }

.activeNav{
    font-weight: 800;
}

.intro-text{
  line-height: 2rem;
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* .MuiSvgIcon-root{
    color: #000 !important;
} */

.header-divider{
    font-size: 30px;
    margin-left: 20px;
    margin-right: 20px;
    color: rgb(207, 207, 207);
}

.MuiAccordion-region{
    padding: 20px
}

.atb-card .nav-link{
    font-size: 30px !important;
    font-weight: 700;
}

.index-search-box{
    /* box-shadow: var(--common-box-shadow) !important; */
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4) !important; ;
}

/* .index-search-container{
    background-color: #fff;
} */

.index-search-field{
    border: none !important;
    padding: 20px;
}
.index-search-button{
    width: 100% !important;
    height: 100% !important;
    background-color: #2E55FA;
    color: #fff !important;
    text-align: center;
    border: 0px;
    cursor: pointer;
}

.input-group-text{
    background: none;
    border: none;
    color: #2E55FA;
    font-size: 25px;
    margin-left: 20px;
}

.resume-template-large{
    box-shadow: var(--common-box-shadow) !important;
}
.resume-template-thumbnail-container{
    margin-top: 30px;
}

.resume-template-thumbnail{
    height: 200px;
	box-shadow: 0 0 10px 0 rgba(0,24,128,0.1);
    cursor: pointer;
}

.active-thumbnail{
    box-shadow: 0px 0px 20px 0px rgba(34, 25, 151, 0.767) !important;
}

.unread-notification{
    background-color: rgb(245, 245, 245);
}

.text-black{
    color: #000 !important;
}

#uncontrolled-tab-example-tab-105{
    font-size: 30px;
}

#uncontrolled-tab-example-tab-106{
    font-size: 30px;
}

.progressbar-container{
    margin-bottom: 10px;
    position: sticky !important;
    top: 30px;
    background-color: #fff !important;
    z-index: 300;
}

@media only screen and (max-width: 767px){
    .atb-card {
        /* border-radius: 20px !important; */
        padding:10px;
        text-align: center;
        margin: 10px;
        box-shadow: var(--common-box-shadow);
    }

    .photo-upload-button{
        top: 20px;
        right: 60px;
        width: 35px;
        height: 35px;
    }

    .header-divider{
        display: none;
    }

    .header-divider::before{
        content: ' ';
        border-bottom: 1px solid black;
    }

    .home-page-hero-text{
        font-size: 40px !important;
    }

    .hero-icon{
        align-items: center;
    }

}

@media (min-width: 1200px){
    .container {
        max-width: 1480px;
    }
}

/* Tab Css  */

@media (min-width: 768px) and (max-width:1169px) {
    .home-page-hero-text{
        font-size: 50px !important;
    }
}

@media only screen and (min-width: 500px){
    .atb-card-dashboard {
        border-radius: 20px !important;
        padding:10px;
        text-align: center;
        margin: 10px;
        box-shadow: var(--common-box-shadow);
    }
}

.candidate-custom-register{
    margin: 30px;
    padding: 45px;
    border: 2px solid #A9A9A9;
    border-radius: 8px;
}

@media only screen and (max-width : 500px){
    .candidate-custom-register{
        border: none;
        margin: 10px;
        padding: 10px;
    }
}

.modal-custom-image{
    height: 250px;
}

@media only screen and (max-width: 600px) {
    .modal-custom-image{
        height: 150px !important;
    }
}

.responsive_header{
    font-size: 36px;
}

@media only screen and (max-width: 1200px) {
    .responsive_header{
        font-size: 30px;
    }
}

@media only screen and (max-width: 900px) {
    .responsive_header{
        font-size: 25px;
    }
}

@media only screen and (max-width: 600px) {
    .responsive_header{
        font-size: 20px;
    }
}

.text-c{
    text-align: center;
    text-align: -webkit-center;
}

.text-r{
    text-align: right;
    text-align: -webkit-right;
}

.scroll-to-top{
    position: fixed;
    bottom: 90px;
    right: 50px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #236BFE;
    z-index: 100000;
    color: white;
    cursor: pointer;
}

.buttonWithoutBorderOutlineBgPad
{
    background: none;
    padding: unset;
    border: none;
}

.buttonWithoutBorderOutlineBgPad:focus{
    outline: unset;
}

.ql-editor{
    min-height: 150px;
  }

  .high_paid_job_slider .swiper-container {
    width: 100%;
    height: 100%;
  }

 .high_paid_job_slider .swiper-slide {
    box-sizing: border-box;
    width: calc(100% / 3);
  }

button.site-button.forgot_pass_btn , 
a.site-button.forgot_pass_btn {
    background-color: #0079C1;
}

button.site-button.forgot_pass_btn:hover , 
a.site-button.forgot_pass_btn:hover,
button.site-button.forgot_pass_btn:focus , 
a.site-button.forgot_pass_btn:focus {
    background-color: #075599;
    outline-color: #075599;
}

/* header style modification */
.header_nav_container .nav-link, 
.header_nav_container .nav-link ul li a, 
.header_nav_container button {
    font-family: Epilogue, sans-serif;
    color: #424447;
    font-weight: 600;
    font-size: inherit;
    text-transform: capitalize;
    padding: .8rem 1rem .6rem 1rem;
}
.header_nav_container button {
    box-shadow: none;
    border-radius: 5px;
    margin: 0px 9px 0px 14px;
}
.header_nav_container .nav-link:focus, 
.header_nav_container .nav-link:hover, 
.header_nav_container .activeNav,
.header_nav_container .btn_with_bg:hover,.header_nav_container .lang_holder:hover{
    color: #00457c !important;
    background-color: #DCEFFF;
    border-radius: 4px;
    box-shadow: none;
}
.lang_holder {
    font-family: Epilogue, sans-serif;
    font-weight: 600;
    margin-left: 14px;
    color: #0079C1 !important;
    border-color: #0079C1;
    background: transparent;
    border-width: 2px;
    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    border-radius: 4px;
    padding: 0.25rem 3rem 0.25rem 1rem;
    height: 46px !important;
}

/* profile strength accordion details padding */
.MuiAccordion-region {
    padding: 8px 12px;
}