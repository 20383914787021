.SliderComponent {
  position: relative;
  width: 100%;
  margin: -4rem 0 -7rem;
}

.SliderComponent *,
.SliderComponent *::before,
.SliderComponent *::after {
  box-sizing: border-box !important;
}

.SliderComponent .swiper-wrapper {
  display: flex;
  will-change: transform;
}

.SliderComponent .swiper-slide {
  /* padding: 3rem 0 3rem; */
  height: auto;
}

.SliderComponent .swiper-slide > * {
  height: 100%;
}

.SliderComponent__button {
  position: absolute;
  top: 50%;
  min-width: 43px;
  min-height: 43px;
  border: 0;
  border-radius: 35px;
  background: #00000080;
  opacity: 1;
  z-index: 1000;
  cursor: pointer;
  outline: 0;
  transition: all 0.5s;
}

.SliderComponent__button:hover {
  background: #000c;
}

.SliderComponent__button--prev {
  left: 0;
  transform: translate(-50%, -50%);
}

.SliderComponent__button--next {
  right: 0;
  transform: translate(50%, -50%);
}

.SliderComponent__button::before {
  content: "";
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 20px 20px;
}

.SliderComponent__button--prev::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2216%22%20width%3D%2210%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M41.4%20233.4c-12.5%2012.5-12.5%2032.8%200%2045.3l160%20160c12.5%2012.5%2032.8%2012.5%2045.3%200s12.5-32.8%200-45.3L109.3%20256%20246.6%20118.6c12.5-12.5%2012.5-32.8%200-45.3s-32.8-12.5-45.3%200l-160%20160z%22%2F%3E%3C%2Fsvg%3E");
}

.SliderComponent__button--next::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20height%3D%2216%22%20width%3D%2210%22%20viewBox%3D%220%200%20320%20512%22%3E%3Cpath%20fill%3D%22%23ffffff%22%20d%3D%22M278.6%20233.4c12.5%2012.5%2012.5%2032.8%200%2045.3l-160%20160c-12.5%2012.5-32.8%2012.5-45.3%200s-12.5-32.8%200-45.3L210.7%20256%2073.4%20118.6c-12.5-12.5-12.5-32.8%200-45.3s32.8-12.5%2045.3%200l160%20160z%22%2F%3E%3C%2Fsvg%3E");
}

.swiper-pagination{
  position: relative;
}

.swiper-pagination-bullet-active{
  background: #DB1616;
}

.custom_pagination_bullet{
  height: 12px;
  width: 30px;
  border-radius: 6px;
}

@media (max-width: 767px) {
  .SliderComponent__button--prev {
    transform: translate(-30%, -50%);
  }
  .SliderComponent__button--next {
    transform: translate(30%, -50%);
  }
}
